import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Button, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до SkisaPro
			</title>
			<meta name={"description"} content={"У світі, де домінують цифрові технології, SkisaPro є маяком для аудіо-пуристів та музичних ентузіастів."} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до SkisaPro"} />
			<meta property={"og:description"} content={"У світі, де домінують цифрові технології, SkisaPro є маяком для аудіо-пуристів та музичних ентузіастів."} />
			<meta property={"og:image"} content={"https://ua.skisapro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.skisapro.com/img/old.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.skisapro.com/img/old.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.skisapro.com/img/old.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.skisapro.com/img/old.png"} />
		</Helmet>
		<Components.Header />
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="10px 10px 10px 10px"
				>
					<Image src="https://ua.skisapro.com/img/1.jpg" display="block" width="100%" />
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 58px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						SkisaPro
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						У світі, де домінують цифрові технології, SkisaPro є маяком для аудіо-пуристів та музичних ентузіастів. На наших полицях - ретельно відібрана колекція вінілових платівок, від вічної класики до сучасних хітів. SkisaPro - це не просто магазин, це святилище, де музика оживає, запрошуючи вас відчути звук у його найавтентичнішій формі.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 32px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						Відкрийте для себе душу музики у SkisaPro
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--primary"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							Завітайте до SkisaPro і перенесіться у світ, де кожна вінілова платівка зберігає всесвіт мелодій, емоцій та історій. Наша колекція охоплює жанри, епохи та культури, пропонуючи звукову подорож, яка запалює душу та надихає розум. Тут музику не просто слухають - її відчувають, бачать і глибоко переживають.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://ua.skisapro.com/img/2.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 900 42px/1.2 --fontFamily-mono"
				padding="0px 0px 20px 0px"
				width="100%"
				text-align="center"
			>
				Чому варто обрати SkisaPro?
			</Text>
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Багате звукове полотно
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наша різноманітна колекція гарантує, що незалежно від вашого смаку, ви знайдете щось, що резонує з вами. Від рок-легенд до джазових ікон, від інді-перлин до класичних шедеврів - SkisaPro є справжнім слуховим скарбом.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Досвід SkisaPro
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Окрім записів, ми пропонуємо станції прослуховування, де ви можете зануритися в тепле, аналогове звучання вінілу перед тим, як зробити свій вибір. Йдеться про створення зв'язку, а не лише про транзакцію.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Компетентний персонал, пристрасна спільнота
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наші співробітники не просто працівники - вони ентузіасти вінілу, які прагнуть допомогти вам відкрити для себе нову музику або знайти рідкісний альбом. Приєднуйтесь до спільноти, де створюють музику і діляться спогадами.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Ексклюзивні видання та події
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Отримайте доступ до лімітованих видань та подій лише для членів клубу, де збираються меломани, щоб відсвяткувати презентації альбомів, виступи артистів та вечірки з прослуховуванням вінілових платівок.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});